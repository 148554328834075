import { noDots } from '../../../utils/functions';
import { removePhoneMask, startWithZero } from '../../../utils/mask';

export const convertToBackendNames = (data: any) => {
  const payload: any = {
    additionalAddressData: data.complement,
    address: data.address,
    addressDistrict: data.neighborhood,
    addressNumber: data.num ? noDots(data.num) : 0,
    cep: data.cep,
    city: data.city ? data.city.label : '',
    email: data.email,
    fantasyName: data.displayName,
    name: data.contactName,
    phone: startWithZero(data.phone),
    state: data.state ? data.state.label : '',
    whatsapp: startWithZero(data.whatsapp),
  };

  payload.contacts = [];

  if (data.secondPhone) {
    payload.contacts.push({
      type: '1',
      infoContact: startWithZero(removePhoneMask(data.secondPhone)),
    });
  }
  if (data.thirdPhone) {
    payload.contacts.push({
      type: '1',
      infoContact: startWithZero(removePhoneMask(data.thirdPhone)),
    });
  }

  if (data.secondEmail) {
    payload.contacts.push({ type: '2', infoContact: data.secondEmail });
  }

  if (data.thirdEmail) {
    payload.contacts.push({ type: '2', infoContact: data.thirdEmail });
  }

  return payload;
};

export enum numberType {
  PHONE = '1',
  EMAIL = '2',
  WHATSAPP = '3',
  OTHERS = '4',
}
