import React from 'react';
import cx from 'classnames';
import { Field } from 'formik';
import find from 'lodash/find';
import get from 'lodash/get';
import some from 'lodash/some';
import { Checkbox } from '../../../../../components/Checkbox';
import { Grid } from '../../../../../components/Grid';
import { GridCell } from '../../../../../components/GridCell';
import { daysOptions } from '../../../../../components/ShopData/Complete/components/ShopOpeningHours';
import { TextInput } from '../../../../../components/TextInput';
import { time } from '../../../../../utils/mask';
import { Buttons } from '../../../components/Buttons';
import { ErrorEditing } from '../../../components/ErrorEditing';
import { SuccessEditing } from '../../../components/SuccessEditing';
import { BusinessHours } from '../../../interfaces/userServices';
import styles from './style.module.css';

interface ICheckbox {
  id: number | string;
  textLabel: string;
}

interface IOpeningHoursForm {
  saveDay: (selected: BusinessHours[]) => void;
  selectedDays: BusinessHours[];
  removeDay: (day: number | string) => void;
  handleChange: () => void;
  overlay: number;
  setOverlay: (e: number) => void;
  showSuccessMessage: boolean;
  showErrorMessage: boolean;
  errorMessage?: string;
  resetForm: () => void;
  title: string;
  handleSubmit: (e: any) => void;
}

const getValue = (businessHours: BusinessHours[], day: number | string) => {
  const item = find(businessHours, { day });

  return (key: string) => get(item, key) || null;
};

export const OpeningHoursForm = ({
  saveDay,
  selectedDays,
  removeDay,
  overlay,
  setOverlay,
  title,
  showSuccessMessage,
  showErrorMessage,
  resetForm,
  handleSubmit,
  errorMessage,
}: IOpeningHoursForm) => {
  const discardChanges = (e: React.FormEvent<HTMLInputElement>) => {
    e.stopPropagation();
    resetForm();
    setOverlay(0);
  };

  const onChange = (item: ICheckbox) => {
    const value = getValue(selectedDays, item.id);
    const newItem = {
      day: item.id,
      initialTime: value('initialTime'),
      endTime: value('initialTime'),
    };

    if (some(selectedDays, ['day', newItem.day])) {
      return removeDay(newItem.day);
    }

    const newService = [...selectedDays, newItem];
    saveDay(newService);
  };

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = evt.target;
    const [, field, day] = id.split('-');
    const idx = selectedDays.findIndex(
      ({ day: businessDay }: BusinessHours) => businessDay === day,
    );

    if (idx > -1) {
      const newArr = [...selectedDays];

      if (field === 'initialTime') {
        newArr[idx].initialTime = value;
      }
      if (field === 'endTime') {
        newArr[idx].endTime = value;
      }
      saveDay(newArr);
    }
  };

  const classGrid = cx('pt4 pb4', styles.Grid);

  const daysOptionsForm = daysOptions.map(day => {
    const { id } = day;
    const selectedDay = find(
      selectedDays,
      ({ day: businessDay }: BusinessHours) => `${businessDay}` === id,
    );
    const isChecked = some(selectedDays, { day: id });
    const endTimeValue = selectedDay && selectedDay.endTime;
    const initialTimeValue = selectedDay && selectedDay.initialTime;
    const changeValue = () => {
      onChange(day);
    };

    return (
      <Grid className={classGrid} key={`grid-${id}`}>
        <GridCell width={[1, 1 / 3]} py={1} pt={4} maxWidth={130}>
          <Checkbox
            id={`${title}-day-${id}`}
            name={`${title}[${id}].day`}
            key={`checkbox-${id}`}
            textLabel={day.textLabel}
            value={id}
            checked={isChecked}
            onChange={changeValue}
          />
        </GridCell>
        <GridCell width={[1, 1 / 6]} py={1} maxWidth={80}>
          <Field
            id={`${title}-initialTime-${id}`}
            name={`${title}[${id}].initialTime`}
            labeltext="Abre"
            value={initialTimeValue || ''}
            onChange={handleChange}
            component={TextInput}
            maxLength={5}
            disabled={!isChecked}
            mask={time}
          />
        </GridCell>
        <GridCell width={[1, 1 / 11]} p={1} pt={5} maxWidth={30}>
          <div className={styles.separator} />
        </GridCell>
        <GridCell width={[1, 1 / 6]} py={1} maxWidth={80}>
          <Field
            id={`${title}-endTime-${id}`}
            name={`${title}[${id}].endTime`}
            labeltext="Fecha"
            value={endTimeValue || ''}
            onChange={handleChange}
            component={TextInput}
            maxLength={5}
            disabled={!isChecked}
            mask={time}
          />
        </GridCell>
      </Grid>
    );
  });

  return (
    <form onSubmit={handleSubmit}>
      {daysOptionsForm}
      <Buttons shouldRender={overlay === 6 && !showSuccessMessage} secondClick={discardChanges} />
      <SuccessEditing isForm shouldRender={showSuccessMessage} />
      <ErrorEditing isForm shouldRender={showErrorMessage} message={errorMessage} />
    </form>
  );
};
