import React from 'react';
import cx from 'classnames';
import { Icon } from '../../../components/Icons';
import styles from '../StepTwo/styles.module.css';

interface Props {
  isForm: boolean;
  shouldRender?: boolean;
}

export const SuccessEditing = ({ isForm, shouldRender = true }: Props) => {
  if (!shouldRender) {
    return null;
  }

  const cxMain = cx(
    {
      mt16: isForm,
      mt24: !isForm,
    },
    'pv24 pl24-m flex items-end items-center-m justify-center justify-start-m',
    styles.bgLightGreen,
    styles.changesSaved,
  );

  return (
    <div className={cxMain}>
      <Icon name="SvgIconSuccess" width={36} height={36} />
      <div className="fw6 f14 grass-green ml12 mt8-m flex flex-column flex-row-m">
        <span> Sua alteração foi </span>
        <span className="ml4-m"> salva com sucesso </span>
      </div>
    </div>
  );
};
