import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Loader } from '../../../components/Loaders';
import { Highlight } from '../../../components/Overlay';
import { cep as formatCep, cellPhone as formatCellphone } from '../../../utils/mask';
import { fullProfileValidation, profileValidation } from '../../../utils/validationForm';
import { putUserData } from '../services/editProfile';
import { treatData } from '../utils/treatData';
import { ProfileForm } from './ProfileForm';
import { convertToBackendNames, numberType } from './utils';

interface Props {
  type?: 'PJ' | 'PF';
  overlay: number;
  setOverlay: (overlay: number) => void;
  initialData: any;
  userId: number;
  reload: () => void;
}

export const StepOne = ({
  type = 'PJ',
  overlay,
  setOverlay,
  initialData,
  userId,
  reload,
}: Props) => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorEmail, setErrorEmail] = useState('');

  useEffect(() => {
    if (showSuccessMessage) {
      setTimeout(() => {
        setShowSuccessMessage(false);
      }, 3000);
    }
  }, [showSuccessMessage]);

  const preventTrunc = (numberString: string) => {
    return numberString[0] === '0' ? numberString.substring(1) : numberString;
  };

  const submitData = (values: any) => {
    setLoading(true);
    values = convertToBackendNames(values);
    // tslint:disable-next-line: prefer-const
    let payload = initialData;
    // tslint:disable-next-line: forin
    for (const key in values) {
      if (values[key] && values[key].label) {
        payload[key] = values[key].label;
      } else {
        payload[key] = values[key];
      }
    }
    payload = treatData(payload);
    putUserData(userId, payload)
      .then((response: any) => {
        setOverlay(0);
        if (response.status === 200) {
          setShowSuccessMessage(true);
          reload();
        }
      })
      .catch((err: any) => {
        setErrorEmail(err.data.message);
        setOverlay(1);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderProfileForm = () => {
    const {
      fantasyName: displayName,
      cep,
      state,
      city,
      contacts,
      addressDistrict: neighborhood,
      address,
      addressNumber: num,
      additionalAddressData: complement,
      name: contactName,
      email,
      phone,
      whatsapp,
    } = initialData;

    const bigFormValues = {
      cep: cep ? formatCep(cep) : '',
      state: {
        label: state,
        value: state,
      },
      city: {
        label: city,
        value: city,
      },
      neighborhood,
      address,
      num,
      displayName,
      complement,
    };
    const smallFormValues = {
      contactName,
      email,
      contacts,
      phone: phone ? formatCellphone(preventTrunc(phone)) : '',
      whatsapp: whatsapp ? formatCellphone(preventTrunc(whatsapp)) : '',
      secondPhone: '',
      thirdPhone: '',
      secondEmail: '',
      thirdEmail: '',
    };
    const initialValues =
      type === 'PJ' ? { ...bigFormValues, ...smallFormValues } : smallFormValues;

    const cellPhoneList = initialValues.contacts.filter(
      (contact: any) => contact.type === numberType.PHONE,
    );
    const emailList = initialValues.contacts.filter(
      (contact: any) => contact.type === numberType.EMAIL,
    );

    initialValues.secondPhone = cellPhoneList[0]
      ? formatCellphone(preventTrunc(cellPhoneList[0].infoContact))
      : '';

    initialValues.thirdPhone = cellPhoneList[1]
      ? formatCellphone(preventTrunc(cellPhoneList[1].infoContact))
      : '';

    initialValues.secondEmail = emailList[0] ? emailList[0].infoContact : '';
    initialValues.thirdEmail = emailList[1] ? emailList[1].infoContact : '';

    const validationSchema = type === 'PJ' ? fullProfileValidation : profileValidation;

    if (loading) {
      return <Loader />;
    }

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={submitData}
        enableReinitialize
        validationSchema={validationSchema}
        // tslint:disable-next-line: jsx-no-lambda jsx-no-multiline-js
        render={(props: any) => (
          <ProfileForm
            type={type}
            overlay={overlay}
            setOverlay={setOverlay}
            showSuccessMessage={showSuccessMessage}
            errorEmail={errorEmail}
            title="profileForm-"
            {...props}
          />
        )}
      />
    );
  };
  return (
    <>
      <Highlight
        setOverlay={setOverlay}
        overlay={overlay}
        stepId={1}
        title="Informações de contato"
        className="pb8"
      >
        {renderProfileForm()}
      </Highlight>
    </>
  );
};
