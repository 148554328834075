// tslint:disable:jsx-no-multiline-js
import React, { ReactElement } from 'react';
import cx from 'classnames';
import { map, includes, orderBy } from 'lodash';
import Media from 'react-media';
import { Checkbox } from '../../../../../components/Checkbox';
import { Grid } from '../../../../../components/Grid';
import { GridCell } from '../../../../../components/GridCell';
import { Buttons } from '../../../components/Buttons';
import { SuccessEditing } from '../../../components/SuccessEditing';
import styles from './styles.module.css';

interface OptionalItems {
  id: string;
  textLabel: string;
}

interface Props {
  servicesItems: OptionalItems[];
  saveServices: (optionals: string[]) => void;
  removeServices: (optionals: string) => void;
  selectedServices: string[];
  overlay: number;
  setOverlay: (a: any) => void;
  resetForm: () => void;
  handleSubmit: () => void;
  title: string;
  showSuccessMessage: boolean;
  isHighlighted: boolean;
  setHighlighted: (value: boolean) => void;
  isAdmin: boolean;
}
export const ServiceCardForm = ({
  servicesItems,
  saveServices,
  selectedServices,
  removeServices,
  overlay,
  setOverlay,
  showSuccessMessage,
  resetForm,
  handleSubmit,
  setHighlighted,
  isHighlighted,
  isAdmin,
}: Props) => {
  const container = cx(
    'mb16 br2 b bg-white ba h-100 transition-linear overflow-hidden transition-ease pt8 pb4',
    styles.container,
  );

  const containerList = cx(
    'w-100-ns pl8 pr8 pl16-ns pr16-ns pl16-m pr16-m pl16-l pr16-l list mv0',
    styles.listOptionalItensContainer,
  );

  const discardChanges = (e: React.FormEvent<HTMLInputElement>) => {
    e.stopPropagation();
    resetForm();
    setOverlay(0);
  };

  const onChange = (service: string): void => {
    if (includes(selectedServices, service)) {
      removeServices(service);
    } else {
      const newService = [...selectedServices, service];
      saveServices(newService);
    }
  };

  const orderItems = (isMobileMedia = true): OptionalItems[] => {
    const ordered: OptionalItems[] = orderBy(servicesItems, ['textLabel'], ['asc']);
    if (isMobileMedia) {
      return ordered;
    }
    const newOrdered: OptionalItems[] = [];
    let currentColumn = 1;
    let column1Iterator = 0;
    let column2Iterator = Math.ceil(ordered.length / 2);

    ordered.map(item => {
      if (currentColumn === 1) {
        newOrdered[column1Iterator] = item;
        currentColumn++;
        column1Iterator++;
      } else if (currentColumn === 2) {
        newOrdered[column2Iterator] = item;
        column2Iterator++;
        currentColumn = 1;
      }
    });

    return newOrdered;
  };

  const renderServiceItems = (isMobileMedia = true) => {
    const items: OptionalItems[] = orderItems(isMobileMedia);
    return map(items, item => {
      const { id, textLabel } = item;
      const checked = includes(selectedServices, textLabel);
      const change = () => onChange(textLabel);
      return (
        <div key={item.id} className="pb8">
          <li className="lh-copy">
            <Checkbox
              checked={checked}
              key={id}
              id={id}
              textLabel={item.textLabel}
              value={textLabel}
              onChange={change}
            />
          </li>
        </div>
      );
    });
  };

  const containerListRenderMediaMatches = (matches: boolean): ReactElement<any> =>
    matches ? <>{renderServiceItems(false)}</> : <>{renderServiceItems(true)}</>;

  const onHighlightedChange = () => setHighlighted(!isHighlighted);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid>
          <GridCell width={[1]}>
            <section>
              <div className={container}>
                <ul className={containerList}>
                  <Media query="(min-width: 768px)">
                    {matches => containerListRenderMediaMatches(matches)}
                  </Media>
                </ul>
              </div>
              {isAdmin && (
                <Checkbox
                  checked={isHighlighted}
                  key="highlight"
                  id="highlight"
                  textLabel="Loja com destaque de serviços"
                  value={isHighlighted}
                  onChange={onHighlightedChange}
                />
              )}
            </section>
          </GridCell>
        </Grid>
        <Buttons shouldRender={overlay === 5 && !showSuccessMessage} secondClick={discardChanges} />
        <SuccessEditing isForm shouldRender={showSuccessMessage} />
      </form>
    </>
  );
};
