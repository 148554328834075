import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { AddImg } from '../../../components/AddPhotos/AddImg';
import { Loader } from '../../../components/Loaders';
import { Highlight } from '../../../components/Overlay';
import { Buttons } from '../components/Buttons';
import { SuccessEditing } from '../components/SuccessEditing';
import { updatePhotos } from '../services/editProfile';
import styles from '../StepTwo/styles.module.css';

interface Photo {
  id: string;
  principal: boolean;
  url: string;
}

interface Props {
  initialData: any;
  userId: number;
  overlay: number;
  setOverlay: (overlay: number) => void;
  reload: () => void;
}

export const StepTen = ({ overlay, setOverlay, userId, initialData, reload }: Props) => {
  const [coverPhoto, setCoverPhoto] = useState('');
  const [hasCoverPhoto, setHasCoverPhoto] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [defaultImage, setDefaultImage] = useState('');
  const [resetTrigger, setResetTrigger] = useState(false);

  useEffect(() => {
    if (showSuccessMessage) {
      setTimeout(() => {
        setShowSuccessMessage(false);
      }, 3000);
    }
  }, [showSuccessMessage]);

  useEffect(() => {
    const { urlBannerPhoto } = initialData;
    if (!!urlBannerPhoto) {
      setHasCoverPhoto(true);
      setCoverPhoto(urlBannerPhoto);
      treatDefaultImage();
    }
  }, [initialData]);

  const treatDefaultImage = () => {
    const { urlBannerPhoto } = initialData;
    if (!!urlBannerPhoto && urlBannerPhoto.indexOf('data:image') === -1) {
      setDefaultImage(`${process.env.FTP_IMAGENS}${initialData.urlBannerPhoto}`);
    } else if (!!urlBannerPhoto) {
      setDefaultImage(urlBannerPhoto);
    }
  };

  const coverTitle = () => <h3 className="self-start mt0 mb24 abbey f18 fw7">Foto de banner</h3>;

  const coverOnDrop = (photo: Photo) => {
    setHasCoverPhoto(true);
    setCoverPhoto(photo.url);
  };

  const coverRemove = () => {
    setCoverPhoto('');
    setHasCoverPhoto(false);
  };

  const coverOnReset = () => {
    const { urlBannerPhoto } = initialData;
    if (!!urlBannerPhoto) {
      setHasCoverPhoto(true);
      setCoverPhoto(initialData.urlBannerPhoto);
    }
    setResetTrigger(true);
  };

  const cxSecondUpload = cx(
    styles.secondUploadWrapper,
    'flex flex-column items-center items-start-ns',
  );

  const cxMainClass = cx('bg-white mt8-ns flex flex-column items-center items-start-ns br10-ns', {
    'pb16-ns': overlay === 3,
  });

  const cxHighlight = cx({
    pb24: overlay !== 3,
  });

  const overlayCover = () => {
    setOverlay(10);
  };

  const saveChanges = () => {
    setLoading(true);
    initialData.urlBannerPhoto = coverPhoto;
    delete initialData.urlCompanyLogo;
    delete initialData.id;
    delete initialData.urlFrontagePhoto;
    updatePhotos(userId, initialData)
      .then((response: any) => {
        if (response.status === 200) {
          setShowSuccessMessage(true);
          reload();
        }
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
        setOverlay(0);
      });
  };

  const undoChanges = (e: any) => {
    e.stopPropagation();
    coverOnReset();
    setOverlay(0);
  };

  const uploadCover = () => {
    if (loading) {
      return <Loader />;
    }
    return (
      <>
        <div onFocus={overlayCover} className={cxMainClass}>
          <div className={cxSecondUpload}>
            <AddImg
              id="banner_company"
              renderTitle={false}
              title={coverTitle}
              sizeLogo="bannerPhoto"
              description="a foto"
              onDrop={coverOnDrop}
              remove={coverRemove}
              hasImage={hasCoverPhoto}
              defaultImage={defaultImage}
              resetTrigger={resetTrigger}
              setReseted={setResetTrigger}
              iconOn="top"
            />
          </div>
        </div>
        <Buttons
          className="pb8-ns"
          firstClick={saveChanges}
          secondClick={undoChanges}
          shouldRender={overlay === 10 && !showSuccessMessage}
        />
        <SuccessEditing isForm={false} shouldRender={showSuccessMessage} />
      </>
    );
  };

  return (
    <>
      <Highlight
        setOverlay={setOverlay}
        overlay={overlay}
        stepId={10}
        title="Foto do banner"
        className={cxHighlight}
      >
        {uploadCover()}
      </Highlight>
    </>
  );
};
