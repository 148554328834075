import React from 'react';
import cx from 'classnames';
import { map, find } from 'lodash';
import moment from 'moment';
import { Grid } from '../../../../components/Grid';
import styles from '../../Complete/style.module.css';

interface BusinessHours {
  day: string;
  initialTime: string;
  endTime: string;
}

interface IsAfeterProps {
  inititalDate: Date;
  dateToCompare: Date;
}

interface IDay {
  id: string;
  textLabel: string;
}

interface Props {
  businessHours: BusinessHours[];
}

export const daysOptions = [
  { id: '1', textLabel: 'Domingo' },
  { id: '2', textLabel: 'Segunda-feira' },
  { id: '3', textLabel: 'Terça-feira' },
  { id: '4', textLabel: 'Quarta-feira' },
  { id: '5', textLabel: 'Quinta-feira' },
  { id: '6', textLabel: 'Sexta-feira' },
  { id: '7', textLabel: 'Sábado' },
];

const isoDayOfWeek = (date: Date) => +moment(date).format('E');

const timeForDate = (time: string) => {
  const splitedTime = (_time: string) => _time.split(':');
  return moment()
    .set({
      hour: +splitedTime(time)[0],
      minute: +splitedTime(time)[1],
    })
    .format();
};

const _isAfter = ({ inititalDate, dateToCompare }: IsAfeterProps) => {
  const formatedInitalDate = moment(inititalDate).format('HH:mm');
  const formatedDateToCompare = moment(dateToCompare).format('HH:mm');
  return formatedInitalDate > formatedDateToCompare;
};

const _isBefore = ({ inititalDate, dateToCompare }: IsAfeterProps) => {
  const formatedInitalDate = moment(inititalDate).format('HH:mm');
  const formatedDateToCompare = moment(dateToCompare).format('HH:mm');
  return formatedInitalDate < formatedDateToCompare;
};

export const isOpen = (initialTime: string, endTime: string, date: Date = originalHours()) => {
  const init = new Date(timeForDate(initialTime));
  const end = new Date(timeForDate(endTime));
  return (
    _isAfter({ inititalDate: date, dateToCompare: init }) &&
    _isBefore({ inititalDate: date, dateToCompare: end })
  );
};

const originalHours = () => new Date(moment().format());

const daySelected = (day: number, businessHours: BusinessHours[]): BusinessHours => {
  const searchDay = find(businessHours, d => d.day === day.toString());

  if (!searchDay) {
    return { day: '0', initialTime: '', endTime: '' };
  }

  return searchDay;
};

const openStatus = (date: Date, initialTime: string, endTime: string) => {
  const open = isOpen(initialTime, endTime, date);
  if (open) {
    return (
      <>
        <p aria-label="Loja está aberta" className="f16 fw6 ma0 grass-green">
          &bull; ABERTO
        </p>
        <p aria-label={`Loja fecha as ${endTime} horas`} className="f16 abbey fw3 mt4">
          Fecha às <b className="fw5">{endTime}</b>
        </p>
      </>
    );
  }

  return (
    <>
      <p aria-label="Loja está fechada" className="f16 fw6 ma0 abbey">
        &bull; FECHADO
      </p>
    </>
  );
};
const formatHour = (_hour: string) => {
  const formatedHour = _hour.split(':');
  if (formatedHour[1] === '00') {
    formatedHour[1] = 'h';
    return formatedHour[0].concat(formatedHour[1]);
  }
  // tslint:disable-next-line: no-console
  return formatedHour.join(':');
};
const renderDays = (businessHours: BusinessHours[]) => {
  const renderGrid = (labelStatus: string, textLabel: string, isToday: boolean) => (
    <Grid className={cx('pv6 f12-m', { b: isToday })}>
      <Grid aria-label={textLabel} width={1 / 2}>
        {textLabel}
      </Grid>
      <Grid aria-label={labelStatus} width={1 / 2}>
        {labelStatus}
      </Grid>
    </Grid>
  );
  const setCurrentDay = (_day: IDay) => {
    const _today = new Date().getDay().toString();
    return _day.id === _today ? true : false;
  };

  const openOrClose = (day: IDay) => {
    const dateStatus = find(businessHours, d => {
      if (d && d.day) {
        return d.day.toString() === day.id;
      }
      return false;
    });

    const _isToday = setCurrentDay(day);
    if (dateStatus) {
      const { initialTime, endTime } = dateStatus;
      const date = originalHours();
      const checkDiffDayOfWeed = isoDayOfWeek(date) !== parseInt(day.id, 10);
      const open = isOpen(initialTime, endTime, date);

      if (open || checkDiffDayOfWeed) {
        const labelStatus = formatHour(initialTime) + '-' + formatHour(endTime);

        return renderGrid(labelStatus, day.textLabel, _isToday);
      }
    }

    return renderGrid('Fechado', day.textLabel, _isToday);
  };

  return map(daysOptions, day => openOrClose(day));
};

export const ShopOpeningHours: React.FC<Props> = ({ businessHours }: Props) => {
  if (!businessHours || !businessHours.length) {
    return null;
  }

  const date = originalHours();
  const dateStatus = daySelected(isoDayOfWeek(date), businessHours);

  const renderOpenStatus = openStatus(date, dateStatus.initialTime, dateStatus.endTime);

  return (
    <section className={cx(styles.w30vw)}>
      {renderOpenStatus}
      <div className="f14 abbey fw3 mt12 mb24">{renderDays(businessHours)}</div>
    </section>
  );
};
