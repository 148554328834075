import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Loader } from '../../../components/Loaders';
import { Highlight } from '../../../components/Overlay';
import { ServiceData } from '../interfaces/userServices';
import { savePartServices } from '../services/editProfile';
import { treatOpeningHours } from '../utils/treatData';
import { DescriptionForm } from './components/DescriptionForm';

interface Props {
  type?: 'PJ' | 'PF';
  overlay: number;
  setOverlay: (overlay: number) => void;
  initialData: ServiceData;
  reload: () => void;
  userId: number;
}

export const StepFive = ({ overlay, setOverlay, initialData, reload, userId }: Props) => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (showSuccessMessage) {
      setTimeout(() => {
        setShowSuccessMessage(false);
      }, 3000);
    }
    if (showErrorMessage) {
      setTimeout(() => {
        setShowErrorMessage(false);
      }, 3000);
    }
  }, [showSuccessMessage, showErrorMessage]);

  const submitData = (values: ServiceData) => {
    setLoading(true);
    const id = initialData.id ? initialData.id : 0;
    let payload = initialData;
    payload = treatOpeningHours(payload);
    payload.description = values.description;
    payload.services = payload.services;
    savePartServices(id, userId, payload)
      .then((response: any) => {
        if (response.status === 200) {
          setShowSuccessMessage(true);
          reload();
        }
      })
      .catch((err: any) => {
        setShowErrorMessage(true);
        return err;
      })
      .finally(() => {
        setLoading(false);
        setOverlay(0);
      });
  };

  const renderForm = (props: any) => (
    <DescriptionForm
      overlay={overlay}
      setOverlay={setOverlay}
      title="descriptionForm"
      showSuccessMessage={showSuccessMessage}
      showErrorMessage={showErrorMessage}
      {...props}
    />
  );

  const renderDescriptionForm = () => {
    if (loading) {
      return <Loader />;
    }

    return (
      <Formik
        initialValues={initialData}
        onSubmit={submitData}
        enableReinitialize
        render={renderForm}
      />
    );
  };

  return (
    <>
      <Highlight
        setOverlay={setOverlay}
        overlay={overlay}
        stepId={4}
        title="Descrição da Loja"
        className="pb8"
      >
        {renderDescriptionForm()}
      </Highlight>
    </>
  );
};
