import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { graphql, StaticQuery } from 'gatsby';
import clone from 'lodash/clone';
import filter from 'lodash/filter';
import map from 'lodash/map';
import { Loader } from '../../../components/Loaders';
import { Highlight } from '../../../components/Overlay';
import { ServiceData } from '../interfaces/userServices';
import { savePartServices } from '../services/editProfile';
import { treatOpeningHours } from '../utils/treatData';
import { ServiceCardForm } from './components/ServiceCardForm';

interface Services {
  id: string;
  name: string;
}
interface Props {
  type?: 'PJ' | 'PF';
  overlay: number;
  setOverlay: (overlay: number) => void;
  initialData: ServiceData;
  reload: () => void;
  data?: Services[];
  userId: number;
  isAdmin: boolean;
}

export const RenderServicesFilter = ({
  overlay,
  setOverlay,
  initialData,
  reload,
  data,
  userId,
  isAdmin,
}: Props) => {
  const { services, highlight } = initialData;

  const baseServices = clone(services);
  const baseHighlight = clone(highlight);

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [selectedServices, setSelectedServices] = useState<string[]>([]);
  const [isHighlighted, setHighlighted] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (showSuccessMessage) {
      setTimeout(() => {
        setShowSuccessMessage(false);
      }, 3000);
    }
  }, [showSuccessMessage]);

  useEffect(() => {
    if (services && services.length) {
      const options = filter(services, value => !!value);
      setSelectedServices(options);
    }
  }, [services]);

  useEffect(() => {
    setHighlighted(Boolean(highlight));
  }, [highlight]);

  const submitData = () => {
    setLoading(true);
    const id = initialData.id ? initialData.id : 0;
    let payload = initialData;
    payload = treatOpeningHours(payload);
    payload.services = selectedServices;
    payload.highlight = isHighlighted;
    savePartServices(id, userId, payload)
      .then((response: any) => {
        if (response.status === 200) {
          setShowSuccessMessage(true);
          reload();
        }
      })
      .catch((err: any) => err)
      .finally(() => {
        setLoading(false);
        setOverlay(0);
      });
  };

  const servicesItems = map(data, s => ({ id: s.id, textLabel: s.name }));

  const saveServices = (values: string[]) => {
    setSelectedServices(values);
  };

  const removeServices = (service: string) => {
    const idx = selectedServices.indexOf(service);
    if (idx > -1) {
      selectedServices.splice(idx, 1);
    }
  };

  const resetForm = () => {
    setSelectedServices(baseServices);
    setHighlighted(Boolean(baseHighlight));
  };

  const render = (props: any) => (
    <ServiceCardForm
      overlay={overlay}
      setOverlay={setOverlay}
      title="serviceForm"
      showSuccessMessage={showSuccessMessage}
      servicesItems={servicesItems}
      saveServices={saveServices}
      selectedServices={selectedServices}
      setHighlighted={setHighlighted}
      isHighlighted={isHighlighted}
      removeServices={removeServices}
      {...props}
      resetForm={resetForm}
      isAdmin={isAdmin}
    />
  );

  const renderForm = () => {
    if (loading) {
      return <Loader />;
    }

    return (
      <Formik
        initialValues={{ services }}
        onSubmit={submitData}
        enableReinitialize
        render={render}
      />
    );
  };

  return (
    <>
      <Highlight
        setOverlay={setOverlay}
        overlay={overlay}
        stepId={5}
        title="Serviços Oferecidos"
        className="pb24"
      >
        {renderForm()}
      </Highlight>
    </>
  );
};

export const StepSix = ({ ...props }: Props) => {
  const optionalsData = graphql`
    query {
      allDirectusTipoDeServico(filter: { status: { eq: "published" } }) {
        nodes {
          id
          name
        }
      }
    }
  `;

  const renderServiceFilter = (data: any) => {
    const {
      allDirectusTipoDeServico: { nodes },
    } = data;

    return <RenderServicesFilter data={nodes} {...props} />;
  };
  return <StaticQuery query={optionalsData} render={renderServiceFilter} />;
};
