import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { Field } from 'formik';
import find from 'lodash/find';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import { Divider } from '../../../components/Divider';
import { Grid } from '../../../components/Grid';
import { GridCell } from '../../../components/GridCell';
import { DataServiceIbge } from '../../../services/DataServiceIbge';
import { callAll } from '../../../utils/callAll';
import { SelectType } from '../../../utils/functions';
import { cellPhone, removeSpecialCharacters, formatKm } from '../../../utils/mask';
import { cep as maskCep } from '../../../utils/mask';
import { Buttons } from '../components/Buttons';
import { SuccessEditing } from '../components/SuccessEditing';
import { Tooltip } from '../StepTwo/Tooltip';
import { CustomInputComponent } from './CustomInputComponent';
import { CustomSelectComponent } from './CustomSelectComponent';
import { setFields, formatCepResponse, emptyAddressFields } from './functions';
import styles from './style.module.css';

export const ProfileForm = ({
  values,
  title,
  handleChange,
  setOverlay,
  setFieldTouched,
  setFieldValue,
  overlay,
  handleSubmit,
  type,
  showSuccessMessage,
  resetForm,
  errorEmail,
}: any) => {
  const [states, setStates] = useState<SelectType[]>([]);
  const [citys, setCitys] = useState<SelectType[]>([]);
  const [hasValidCep, setHasValidCep] = useState(false);

  useEffect(() => {
    DataServiceIbge.getUf().then((res: any) => {
      setStates(orderBy(res || [], ['label', 'value'], ['asc', 'desc']));
    });
  }, []);

  useEffect(() => {
    if (!values.state) {
      return;
    }

    const value = treatValue();
    const stateParam =
      typeof value === 'number'
        ? value
        : get(
            find(states, s => s.label === value),
            'value',
          );
    getCities(stateParam);
  }, [states]);

  useEffect(() => {
    if (values.state === '') {
      return setCitys([]);
    }
    if (values.cep && values.cep.length !== 9) {
      setHasValidCep(false);
    }
  }, [values]);

  useEffect(() => {
    if (errorEmail) {
      setOverlay(1);
    }
  }, [errorEmail]);

  const treatValue = () => {
    if (values.state.value) {
      return values.state.value;
    } else {
      return values.state;
    }
  };

  const getCities = (stateParam: any) => {
    if (stateParam) {
      DataServiceIbge.getCity(stateParam)
        .then((response: any) => {
          setCitys(response);
        })
        .catch((err: any) => console.error(err));
    }
  };

  const discardChanges = (e: any) => {
    e.stopPropagation();
    resetForm();
    setOverlay(0);
  };

  const onChangeSelect = (e: any, fieldName: string) => setFieldValue(fieldName, e);

  const getCepInfo = async (cepInput: string) => {
    const { default: getByCep } = await import('cep-promise');
    getByCep(cepInput)
      .then(res => {
        setHasValidCep(true);
        setFields(formatCepResponse(res, states), setFieldValue);
      })
      .catch(() => {
        setHasValidCep(false);
        setFields(emptyAddressFields, setFieldValue);
      });
  };

  const fillCepForms = async (e: any) => {
    setFieldTouched('cep', true);
    if (e.target.value.length === 9) {
      await getCepInfo(e.target.value);
    }
  };

  const tooltipContent = (
    <div className="flex flex-column">
      <span className="fw7"> Adicione um número com Whatsapp. </span>
      <span className="fw3"> Suas chances de venda serão maiores. </span>
    </div>
  );
  const {
    contactName,
    email,
    secondEmail,
    thirdEmail,
    phone,
    secondPhone,
    thirdPhone,
    whatsapp,
    displayName,
    cep,
    state,
    city,
    address,
    num,
    complement,
    neighborhood,
  } = values;

  const renderAdressFields = () => {
    if (type === 'PF') {
      return null;
    }
    return (
      <>
        <GridCell width={1} className={styles.largeInput}>
          <Field
            name="displayName"
            id={`${title}displayName`}
            labeltext="Nome de exibição"
            type="text"
            value={displayName}
            component={CustomInputComponent}
          />
        </GridCell>
        <Grid className={styles.largeInput}>
          <GridCell width={7 / 12} className="pr8 pr12-ns">
            <Field
              name="cep"
              id={`${title}cep`}
              labeltext="CEP"
              type="text"
              value={cep}
              component={CustomInputComponent}
              mask={maskCep}
              onChange={callAll(handleChange, fillCepForms)}
            />
          </GridCell>
          <GridCell width={5 / 12} className="pl8 pl12-ns">
            <Field
              name="state"
              id={`${title}state`}
              title="Estado"
              value={state}
              options={states}
              disabled={state && hasValidCep}
              component={CustomSelectComponent}
              // tslint:disable-next-line: jsx-no-lambda
              onChange={(e: any) => onChangeSelect(e, 'state')}
            />
          </GridCell>
        </Grid>
        <GridCell width={1} className={styles.largeInput}>
          <Field
            name="city"
            id={title + 'city'}
            title="Cidade"
            value={city}
            options={citys}
            disabled={city && hasValidCep}
            component={CustomSelectComponent}
            // tslint:disable-next-line: jsx-no-lambda
            onChange={(e: any) => onChangeSelect(e, 'city')}
          />
        </GridCell>
        <GridCell width={1} className={styles.largeInput}>
          <Field
            name="neighborhood"
            id={`${title}neighborhood`}
            labeltext="Bairro"
            type="text"
            value={neighborhood}
            component={CustomInputComponent}
          />
        </GridCell>
        <GridCell width={1} className={styles.largeInput}>
          <Field
            name="address"
            id={`${title}address`}
            labeltext="Endereço"
            type="text"
            value={address}
            component={CustomInputComponent}
          />
        </GridCell>
        <Grid className={styles.largeInput}>
          <GridCell width={6 / 12} className="pr8 pr12-ns">
            <Field
              name="num"
              id={`${title}num`}
              labeltext="Número"
              type="text"
              value={num}
              mask={formatKm}
              component={CustomInputComponent}
            />
          </GridCell>
          <GridCell width={6 / 12} className="pl8 pl12-ns">
            <Field
              name="complement"
              id={`${title}complement`}
              labeltext="Complemento"
              type="text"
              value={complement}
              component={CustomInputComponent}
            />
          </GridCell>
        </Grid>
        <Divider className="mb24 mt8" />
      </>
    );
  };
  return (
    <form onSubmit={handleSubmit}>
      {renderAdressFields()}
      <GridCell width={1} className={styles.largeInput}>
        <Field
          name="contactName"
          id={`${title}contactName`}
          labeltext={type === 'PJ' ? 'Nome do contato' : 'Nome'}
          type="text"
          iconleft="SvgIconName"
          iconId="profile"
          value={contactName}
          component={CustomInputComponent}
          onChange={handleChange}
        />
      </GridCell>
      <GridCell width={1} className={styles.largeInput}>
        <Field
          name="email"
          id={`${title}email`}
          labeltext="E-mail principal"
          type="text"
          iconleft="SvgIconMail"
          iconId="profile"
          value={email}
          component={CustomInputComponent}
          onChange={handleChange}
          mask={removeSpecialCharacters}
          errorMessage={errorEmail}
        />
      </GridCell>
      <GridCell width={1} className={styles.largeInput}>
        <Field
          name="secondEmail"
          id={`${title}secondEmail`}
          labeltext="E-mail"
          type="text"
          iconleft="SvgIconMail"
          value={secondEmail}
          component={CustomInputComponent}
          onChange={handleChange}
          mask={removeSpecialCharacters}
        />
      </GridCell>
      <GridCell width={1} className={styles.largeInput}>
        <Field
          name="thirdEmail"
          id={`${title}thirdEmail`}
          labeltext="E-mail"
          type="text"
          iconleft="SvgIconMail"
          value={thirdEmail}
          component={CustomInputComponent}
          onChange={handleChange}
          mask={removeSpecialCharacters}
        />
      </GridCell>
      <Grid className={cx('db flex-ns', styles.largeInput)}>
        <GridCell width={[1, 1 / 2]} className="pr12-m pr12-l">
          <Field
            name="phone"
            id={`${title}phone`}
            labeltext="Telefone"
            type="text"
            iconleft="SvgIconCellphone"
            value={phone}
            component={CustomInputComponent}
            onChange={handleChange}
            mask={cellPhone}
          />
        </GridCell>
        <GridCell width={[1, 1, 1 / 2, 1 / 2]} className="pl12-m pl12-l">
          <div className="w-100 w-50-ns w-100-m w-100-l">
            <Tooltip
              content={tooltipContent}
              bgColor="green"
              className="grass-green"
              showTooltip
              small
            >
              <Field
                name="whatsapp"
                id={`${title}whatsapp`}
                labeltext="Whatsapp"
                type="text"
                iconleft="SvgIconWhatsappLine"
                value={whatsapp}
                component={CustomInputComponent}
                onChange={handleChange}
                mask={cellPhone}
              />
            </Tooltip>
          </div>
        </GridCell>
        <GridCell width={[1, 1 / 2]} className="pr12-ns">
          <Field
            name="secondPhone"
            id={`${title}secondPhone`}
            labeltext="Telefone"
            type="text"
            iconleft="SvgIconCellphone"
            value={secondPhone}
            component={CustomInputComponent}
            onChange={handleChange}
            mask={cellPhone}
          />
        </GridCell>
        <GridCell width={[1, 1 / 2]} className="pl12-ns">
          <Field
            name="thirdPhone"
            id={`${title}thirdPhone`}
            labeltext="Telefone"
            type="text"
            iconleft="SvgIconCellphone"
            value={thirdPhone}
            component={CustomInputComponent}
            onChange={handleChange}
            mask={cellPhone}
          />
        </GridCell>
      </Grid>
      <Buttons shouldRender={overlay === 1 && !showSuccessMessage} secondClick={discardChanges} />
      <SuccessEditing isForm shouldRender={showSuccessMessage} />
    </form>
  );
};
