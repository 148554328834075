import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import Measure from 'react-measure';
import MediaQuery from 'react-responsive';
import styles from './styles.module.css';

interface Props {
  children: React.ReactNode;
  content: React.ReactNode;
  className?: string;
  bgColor: string;
  showTooltip?: boolean;
  triggerOnShow?: () => void;
  small?: boolean;
}

export const Tooltip = ({
  children,
  bgColor,
  className,
  content,
  showTooltip = true,
  triggerOnShow,
  small = false,
}: Props) => {
  const [bounds, setBounds] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (showTooltip && triggerOnShow) {
      triggerOnShow();
    }
  }, [showTooltip]);

  const cxTooltip = cx(
    'absolute ph12 tc tl-ns pv12 f13 br10 pa0 relative lh-title w-100',
    className,
    styles.tooltip,
    {
      [styles.normalSize]: !small,
      [styles.smallSize]: small,
      [styles.borderGreen]: bgColor === 'green',
    },
  );

  const getTooltip = ({ measureRef }: any) => {
    const { height } = bounds;
    const tooltipStyle = {
      left: 'calc( 100% + 25px )',
      top: `calc(50% - ${height / 2}px)`,
    };
    const tooltipResponsiveStyle = {
      top: 'calc( 100% + 25px )',
    };

    const tooltipWithStyle = (style: any) => {
      return (
        <div className={cxTooltip} ref={measureRef} style={style}>
          {content}
        </div>
      );
    };

    return (
      <>
        <MediaQuery maxWidth={767}>{tooltipWithStyle(tooltipResponsiveStyle)}</MediaQuery>
        <MediaQuery minWidth={768}>{tooltipWithStyle(tooltipStyle)}</MediaQuery>
      </>
    );
  };

  const handleTooltipResize = (event: any) => {
    const {
      bounds: { width, height },
    } = event;

    setBounds({ width, height });
  };

  const treatTooltip = () => {
    if (showTooltip) {
      return (
        <Measure bounds onResize={handleTooltipResize}>
          {getTooltip}
        </Measure>
      );
    }
  };

  const getPadding = () => {
    const { height } = bounds;
    if (showTooltip) {
      return {
        paddingBottom: `${height + 24}px`,
      };
    }
  };

  const cxMain = cx('w-100 w-auto-ns', styles.removePaddingDesktop);

  return (
    <div className={cxMain} style={getPadding()}>
      <div className="relative w-100">
        {children}
        {treatTooltip()}
      </div>
    </div>
  );
};
