import React from 'react';
import cx from 'classnames';
import { TextArea } from '../../../components/TextArea';
import { TextInput } from '../../../components/TextInput';

export const CustomInputComponent = ({ divClass, field, form, errorMessage, ...props }: any) => {
  const { touched, errors } = form;
  const { name, onBlur, onChange } = field;
  const normalizeFieldData = {
    name,
    onBlur,
    onChange,
    errorMessage: errorMessage || (!!touched[field.name] && errors[field.name]),
  };
  const errorsInput = cx({
    mb16: !touched[field.name] || (!errors[field.name] && !!touched[field.name]),
    mb8: !!touched[field.name] && errors[field.name],
  });

  return (
    <div className={errorsInput}>
      <TextInput {...normalizeFieldData} {...props} />
    </div>
  );
};

export const customTextAreaComponent = ({ divClass, field, ...props }: any) => {
  const normalizeFieldData = {
    name: field.name,
    onBlur: field.onBlur,
    onChange: field.onChange,
    errorMessage: !!props.form.touched[field.name] && props.form.errors[field.name],
  };
  const errorsInput = cx({
    mb16:
      !props.form.touched[field.name] ||
      (!props.form.errors[field.name] && !!props.form.touched[field.name]),
    mb8: !!props.form.touched[field.name] && props.form.errors[field.name],
  });
  return (
    <div className={errorsInput}>
      <TextArea {...normalizeFieldData} {...props} />
    </div>
  );
};
