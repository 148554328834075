import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { Button, OutlineButton } from '../../../components/Button';
import { Loader } from '../../../components/Loaders';
import { Highlight } from '../../../components/Overlay';
import { StyledModal } from '../../../components/StyledModal';
import { ErrorEditing } from '../components/ErrorEditing';
import { deleteAccountService } from '../services/deleteAccount';

interface Props {
  overlay: number;
  setOverlay: (overlay: number) => void;
  userId: number;
  cleanUser: () => void;
}

export const StepNine = ({ overlay, setOverlay, userId, cleanUser }: Props) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const handleCloseConfirmModal = () => setShowConfirmModal(false);

  const handleShowConfirmModal = () => setShowConfirmModal(true);

  const handleConfirmDeleteAccount = () => {
    setLoading(true);
    deleteAccountService(userId)
      .then((response: any) => {
        if (response.status === 200) {
          cleanUser();
          navigate('/');
        }
      })
      .catch((error: any) => {
        handleCloseConfirmModal();
        setErrorMessage(
          error.response?.data.message || 'Houve um erro ao deletar sua conta. Contate o suporte',
        );
        return error;
      })
      .finally(() => {
        setLoading(false);
        setOverlay(0);
      });
  };

  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
    }
  }, [errorMessage]);

  return (
    <>
      <Highlight
        setOverlay={setOverlay}
        overlay={overlay}
        stepId={9}
        title="Gerenciar conta"
        className="pb24"
      >
        <Button size="medium" classButton="w-auto" onClick={handleShowConfirmModal}>
          Deletar conta
        </Button>
        <ErrorEditing isForm={false} shouldRender={Boolean(errorMessage)} message={errorMessage} />
      </Highlight>
      <StyledModal isOpen={showConfirmModal} closeModal={handleCloseConfirmModal}>
        <div className="abbey pa24">
          {loading && <Loader />}
          {!loading && (
            <>
              <h1>Tem certeza que deseja deletar sua conta?</h1>
              <h3>Essa ação não pode ser desfeita. E todos os seus anúncios serão removidos.</h3>
              <div className="flex justify-end mt24 flex-column flex-row-l">
                <OutlineButton
                  size="medium"
                  className="w-auto mb16 mr24-l mb0-l"
                  onClick={handleCloseConfirmModal}
                >
                  Cancelar
                </OutlineButton>
                <Button size="medium" classButton="w-auto" onClick={handleConfirmDeleteAccount}>
                  Deletar conta
                </Button>
              </div>
            </>
          )}
        </div>
      </StyledModal>
    </>
  );
};
