import React from 'react';
import cx from 'classnames';

interface Props {
  children: React.ReactNode;
  overlay?: number;
  stepId?: number;
  title?: string;
  subtitle?: string;
  className?: string;
  setOverlay?: (overlay: number) => void;
  isParts?: boolean;
}
export const Highlight = ({
  children,
  overlay = 0,
  stepId = 1,
  title,
  subtitle,
  setOverlay,
  className,
  isParts,
}: Props) => {
  const containerClass = cx(
    'relative br10-ns mb32 abbey',
    {
      'z-3': overlay === stepId,
      'z-1': overlay !== stepId,
      'bg-white ph16 ph24-ns ph32-m ph32 pt24 pt32-m pt32-l': !isParts,
      'w100 pt16': isParts,
    },
    className,
  );
  const titleClass = cx('ma0 b pb0-ns f18', {
    'f24-m f24-l': !isParts,
  });
  const subtitleClass = cx('ma0 mt4 f14 pb0-ns', {
    dn: !subtitle,
    'fw4 b': !isParts,
    fw3: isParts,
  });
  const onClick: any = setOverlay ? () => setOverlay(stepId) : setOverlay;
  return (
    <div className={containerClass} id="edit" onClick={onClick}>
      <h3 className={titleClass}>{title}</h3>
      <h4 className={subtitleClass}>{subtitle}</h4>
      <div className="pt24">{children}</div>
    </div>
  );
};
