import React from 'react';

interface Props {
  overlay?: number;
  setOverlay?: (overlay: number) => void;
}
export const Overlay = ({ overlay = 0, setOverlay }: Props) => {
  if (!overlay) {
    return null;
  }
  const onClick: any = setOverlay ? () => setOverlay(0) : setOverlay;
  return <div className="fixed w-100 h-100 bg-black-30 z-2 db top-0 left-0" onClick={onClick} />;
};
