import find from 'lodash/find';
import get from 'lodash/get';
import map from 'lodash/map';
import { SelectType, toLabelValue } from '../../../utils/functions';

interface NameValueType {
  name: string;
  value: string;
}

export const emptyAddressFields = [
  { name: 'state', value: '' },
  { name: 'city', value: '' },
  { name: 'address', value: '' },
  { name: 'number', value: '' },
  { name: 'complement', value: '' },
  { name: 'neighborhood', value: '' },
];

export const setFields = (
  fields: NameValueType[],
  setField: (field: string, value: string) => void,
) => map(fields, field => setField(field.name, field.value));

export const formatCepResponse = (res: any, states: SelectType[]) => {
  const uf = get(res, 'state', '');
  const ufWithId = find(states, state => state.label === uf) || '';
  const city = get(res, 'city', '');
  return [
    { name: 'state', value: ufWithId },
    { name: 'city', value: city && toLabelValue(city) },
    { name: 'address', value: get(res, 'street', '') },
    { name: 'neighborhood', value: get(res, 'neighborhood', '') },
  ];
};
