import compact from 'lodash/compact';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import uniqWith from 'lodash/uniqWith';
import moment from 'moment';
import { removePhoneMask, removeCepMask } from '../../../utils/mask';

export const treatData = (payload: any) => {
  payload.cep = removeCepMask(payload.cep || '');
  payload.phone = removePhoneMask(payload.phone || '');
  payload.whatsapp = removePhoneMask(payload.whatsapp || '');
  delete payload.secondPhone;
  delete payload.thirdPhone;
  delete payload.id;
  delete payload.urlCompanyLogo;
  delete payload.urlFrontagePhoto;
  return payload;
};

export const treatServiceData = (payload: any) => {
  const businessHours = uniqWith(payload.businessHours, isEqual);
  payload.businessHours = businessHours;
  return payload;
};

export const treatOpeningHours = (payload: any) => {
  payload.businessHours = map(payload.businessHours, item => {
    if (item.day) {
      return {
        day: parseInt(item.day, 10),
        initialTime: item.initialTime,
        endTime: item.endTime,
      };
    }
  });
  payload.businessHours = compact(payload.businessHours);
  return payload;
};

export const isOpeningHourValid = (businessHour: any) => {
  const initialTime = moment(businessHour.initialTime, 'HH:mm');
  const endTime = moment(businessHour.endTime, 'HH:mm');
  return endTime.isAfter(initialTime);
};

export const isOpeningHoursValid = (businessHours: any[]) => {
  for (const businessHour of businessHours) {
    if (!isOpeningHourValid(businessHour) && businessHour.initialTime) {
      return false;
    }
  }
  return true;
};
