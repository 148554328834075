import React from 'react';
import { PageProps } from 'gatsby';
import { Layout } from '../components/Layout';
import { PrivateRoute } from '../components/PrivateRoute';
import { EditProfile } from '../modules/EditProfile';
import { Footer } from '../modules/Footer';
import { Menu } from '../modules/Menu';

const EditProfilePage = ({ ...props }: PageProps) => {
  const pageContent = () => (
    <div className="bg-wild-sand">
      <Layout>
        <Menu menuHasTwoColors={false} />
        <EditProfile />
        <Footer />
      </Layout>
    </div>
  );

  return <PrivateRoute location={props.location.pathname} component={pageContent} />;
};

export default EditProfilePage;
