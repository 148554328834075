import React from 'react';
import cx from 'classnames';
import { Button } from '../../../components/Button';
import { Divider } from '../../../components/Divider';
import styles from '../StepOne/style.module.css';

interface Props {
  shouldRender?: boolean;
  firstClick?: () => void;
  secondClick: (e: any) => void;
  className?: string;
}
export const Buttons = ({
  firstClick,
  secondClick,
  shouldRender = true,
  className = '',
}: Props) => {
  if (!shouldRender) {
    return null;
  }
  const buttonClass = cx('w-100', styles.button);
  const cxMainClass = cx('db mt16', className);
  return (
    <div className={cxMainClass}>
      <Divider />
      <div className="mt8 mt16-ns pb8-m pb8-l items-center flex flex-wrap">
        <div className={buttonClass}>
          <Button
            type="submit"
            classButton="w-100"
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onClick={firstClick ? firstClick : () => {}}
          >
            SALVAR ALTERAÇÃO
          </Button>
        </div>
        <div className="w-100 w-auto-ns mt32 mb24 mt0-ns mb0-ns ml32-ns">
          <button
            className="red underline f14 fw6 bg-transparent bn db di-ns center flex justify-center"
            onClick={secondClick}
            type="reset"
          >
            CANCELAR EDIÇÃO
          </button>
        </div>
      </div>
    </div>
  );
};
