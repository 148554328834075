import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { remove, filter } from 'lodash';
import { Loader } from '../../../components/Loaders';
import { Highlight } from '../../../components/Overlay';
import { BusinessHours, ServiceData } from '../interfaces/userServices';
import { savePartServices } from '../services/editProfile';
import { treatServiceData, treatOpeningHours, isOpeningHoursValid } from '../utils/treatData';
import { OpeningHoursForm } from './components/OpeningHoursForm';

interface Props {
  type?: 'PJ' | 'PF';
  overlay: number;
  setOverlay: (overlay: number) => void;
  initialData: ServiceData;
  reload: () => void;
  userId: number;
}

export const StepSeven = ({ overlay, setOverlay, initialData, reload, userId }: Props) => {
  const { businessHours } = treatServiceData(initialData);
  const [selectedDays, setSelectedDays] = useState<BusinessHours[]>([]);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (showSuccessMessage) {
      setTimeout(() => {
        setShowSuccessMessage(false);
      }, 3000);
    }
  }, [showSuccessMessage, initialData]);

  useEffect(() => {
    if (showErrorMessage) {
      setTimeout(() => {
        setShowErrorMessage(false);
      }, 3000);
    }
  }, [showErrorMessage, initialData]);

  useEffect(() => {
    if (businessHours && businessHours.length) {
      const options = filter(businessHours, value => !!value);
      setSelectedDays(options);
    }
  }, []);

  const submitData = () => {
    setLoading(true);
    const id = initialData.id ? initialData.id : 0;
    if (isOpeningHoursValid(selectedDays)) {
      let payload = initialData;
      payload.businessHours = selectedDays;
      payload = treatOpeningHours(payload);
      savePartServices(id, userId, payload)
        .then((response: any) => {
          if (response.status === 200) {
            setShowSuccessMessage(true);
            reload();
          }
        })
        .catch((error: any) => {
          setShowErrorMessage(true);
          if (error && error.data) {
            const { message } = error.data;
            setErrorMessage(message);
          }
          return error;
        })
        .finally(() => {
          setLoading(false);
          setOverlay(0);
        });
    } else {
      setLoading(false);
      setErrorMessage('Informe um intervalo de horas válido.');
      setShowErrorMessage(true);
    }
  };

  const resetForm = () => {
    setSelectedDays(businessHours);
  };

  const saveDay = (selected: BusinessHours[]) => {
    setSelectedDays(selected);
  };

  const removeDay = (day: number) => {
    remove(selectedDays, (r: BusinessHours) => r.day === day);
  };

  const openingHoursFormComponent = (props: any) => (
    <OpeningHoursForm
      overlay={overlay}
      setOverlay={setOverlay}
      title="businessHours"
      showSuccessMessage={showSuccessMessage}
      showErrorMessage={showErrorMessage}
      errorMessage={errorMessage}
      {...props}
      saveDay={saveDay}
      selectedDays={selectedDays}
      removeDay={removeDay}
      resetForm={resetForm}
    />
  );

  const openingHoursForm = loading ? (
    <Loader />
  ) : (
    <Formik
      initialValues={{ businessHours }}
      onSubmit={submitData}
      enableReinitialize
      render={openingHoursFormComponent}
    />
  );

  return (
    <>
      <Highlight
        setOverlay={setOverlay}
        overlay={overlay}
        stepId={6}
        title="Horário de Funcionamento"
        className="pb8"
      >
        {openingHoursForm}
      </Highlight>
    </>
  );
};
