import React from 'react';
import cx from 'classnames';
import { Select } from '../../../components/Select';
import { SelectOption } from '../../VehicleRegistration/utils/Type';

export const CustomSelectComponent = ({
  form,
  field,
  title,
  options,
  onChange,
  urlId,
  revOptions,
  setReady,
  ready,
  changeOverlay,
  disabled,
  ...props
}: any) => {
  const { name } = field;
  const { values, errors, touched, setFieldValue } = form;
  const handleChange = (fieldValue: SelectOption) => setFieldValue(name, fieldValue);
  const normalizeFieldData = {
    id: title + name,
    title,
    value: values[name],
    options,
    disabled: disabled || !options || !options.length,
    onChange: handleChange,
    selected: values[name],
    errorMessage: !!touched[name] && errors[name],
    locationDropdown: 'AdressFormId',
  };
  const errorsInput = cx({
    mb16: !touched[name] || (!errors[name] && !!touched[name]),
    mb8: !!touched[name] && errors[name],
  });
  return (
    <div className={errorsInput}>
      <Select isFormik {...normalizeFieldData} {...props} />
    </div>
  );
};
