import React from 'react';
import cx from 'classnames';
import { Icon } from '../../../components/Icons';
import styles from '../StepTwo/styles.module.css';

interface Props {
  isForm: boolean;
  shouldRender?: boolean;
  message?: string;
}

const DefaultMessage: React.FC = () => (
  <>
    <span> Houve um erro ao salvar </span>
    <span className="ml4-m"> consulte o suporte </span>
  </>
);

export const ErrorEditing = ({ isForm, message, shouldRender = true }: Props) => {
  if (!shouldRender) {
    return null;
  }

  const messageToRender = message ? <span className="mb8">{message}</span> : <DefaultMessage />;

  const cxMain = cx(
    {
      mt16: isForm,
      mt24: !isForm,
    },
    'pv24 pl24-m flex items-end items-center-m justify-center justify-start-m',
    styles.bgWarning,
    styles.changesSaved,
  );

  return (
    <div className={cxMain}>
      <Icon name="SvgIconWarning" width={36} height={36} />
      <div className="fw6 f14 black-50 ml12 mt8-m flex flex-column flex-row-m">
        {messageToRender}
      </div>
    </div>
  );
};
