import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Loader } from '../../../components/Loaders';
import { Highlight } from '../../../components/Overlay';
import { changePasswordValidation } from '../../../utils/validationForm';
import { putUserData } from '../services/editProfile';
import { treatData } from '../utils/treatData';
import { PasswordForm } from './components/PasswordForm';

interface Props {
  type?: 'PJ' | 'PF';
  overlay: number;
  setOverlay: (overlay: number) => void;
  initialData: any;
  userId: number;
  reload: () => void;
}

export const StepFour = ({ overlay, setOverlay, initialData, userId, reload }: Props) => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (showSuccessMessage) {
      setTimeout(() => {
        setShowSuccessMessage(false);
      }, 3000);
    }
  }, [showSuccessMessage]);

  const submitData = (values: any) => {
    setLoading(true);
    let payload = initialData;
    payload = treatData(payload);
    payload.password = values.password;
    putUserData(userId, payload)
      .then((response: any) => {
        if (response.status === 200) {
          setShowSuccessMessage(true);
          reload();
        }
      })
      .catch((err: any) => err)
      .finally(() => {
        setLoading(false);
        setOverlay(0);
      });
  };

  const renderPasswordForm = () => {
    if (loading) {
      return <Loader />;
    }

    return (
      <Formik
        initialValues={{ password: '', confirmPassword: '' }}
        onSubmit={submitData}
        enableReinitialize
        validationSchema={changePasswordValidation}
        // tslint:disable-next-line: jsx-no-lambda jsx-no-multiline-js
        render={(props: any) => (
          <PasswordForm
            overlay={overlay}
            setOverlay={setOverlay}
            title="passwordForm"
            showSuccessMessage={showSuccessMessage}
            {...props}
          />
        )}
      />
    );
  };

  return (
    <>
      <Highlight
        setOverlay={setOverlay}
        overlay={overlay}
        stepId={4}
        title="Alterar senha"
        className="pb8"
      >
        {renderPasswordForm()}
      </Highlight>
    </>
  );
};
