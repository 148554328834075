import React from 'react';
import { Field } from 'formik';
import { Grid } from '../../../../components/Grid';
import { GridCell } from '../../../../components/GridCell';
import { Buttons } from '../../components/Buttons';
import { SuccessEditing } from '../../components/SuccessEditing';
import { CustomInputComponent } from '../../StepOne/CustomInputComponent';
import styles from '../style.module.css';

export const PasswordForm = ({
  handleChange,
  overlay,
  setOverlay,
  showSuccessMessage,
  resetForm,
  title,
  handleSubmit,
  values,
}: any) => {
  const discardChanges = (e: any) => {
    e.stopPropagation();
    resetForm();
    setOverlay(0);
  };

  const { password, confirmPassword } = values;

  return (
    <form onSubmit={handleSubmit}>
      <Grid className={styles.largeInput}>
        <GridCell width={[1]}>
          <Field
            name="password"
            id={`${title}-password`}
            labeltext="Nova senha"
            type="password"
            iconleft="SvgIconLock"
            component={CustomInputComponent}
            onChange={handleChange}
            value={password}
          />
        </GridCell>
        <GridCell width={[1]}>
          <Field
            name="confirmPassword"
            id={`${title}-confirmPassword`}
            labeltext="Confirme a senha"
            type="password"
            iconleft="SvgIconLock"
            component={CustomInputComponent}
            onChange={handleChange}
            value={confirmPassword}
          />
        </GridCell>
      </Grid>
      <Buttons shouldRender={overlay === 4 && !showSuccessMessage} secondClick={discardChanges} />
      <SuccessEditing isForm shouldRender={showSuccessMessage} />
    </form>
  );
};
