import React from 'react';
import { Field } from 'formik';
import { Grid } from '../../../../components/Grid';
import { GridCell } from '../../../../components/GridCell';
import { Buttons } from '../../components/Buttons';
import { ErrorEditing } from '../../components/ErrorEditing';
import { SuccessEditing } from '../../components/SuccessEditing';
import { customTextAreaComponent } from '../../StepOne/CustomInputComponent';
import styles from '../style.module.css';

interface IValues {
  description: string;
}

interface IDescriptionForm {
  handleChange: () => void;
  overlay: number;
  setOverlay: (e: number) => void;
  showSuccessMessage: boolean;
  showErrorMessage: boolean;
  resetForm: () => void;
  title: string;
  handleSubmit: (e: any) => void;
  values: IValues;
}

export const DescriptionForm = ({
  handleChange,
  overlay,
  setOverlay,
  showSuccessMessage,
  showErrorMessage,
  resetForm,
  title,
  handleSubmit,
  values,
}: IDescriptionForm) => {
  const discardChanges = (e: React.FormEvent<HTMLInputElement>) => {
    e.stopPropagation();
    resetForm();
    setOverlay(0);
  };

  const { description } = values;

  return (
    <form onSubmit={handleSubmit}>
      <Grid className={styles.largeInput}>
        <GridCell width={[1]}>
          <Field
            name="description"
            id={title + 'description'}
            title="Descrição"
            labeltext="Descricao"
            value={description}
            onChange={handleChange}
            component={customTextAreaComponent}
            maxLength={85}
          />
        </GridCell>
      </Grid>
      <Buttons
        shouldRender={overlay === 4 && !showSuccessMessage && !showErrorMessage}
        secondClick={discardChanges}
      />
      <SuccessEditing isForm shouldRender={showSuccessMessage} />
      <ErrorEditing isForm shouldRender={showErrorMessage} />
    </form>
  );
};
